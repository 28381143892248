<template>
    <p class="text-center p-3" v-if="items.length===0">No data available.</p>
    <ul v-else class="custom-ul-list solution-cont font-inter-medium">
        <li v-for="(item,i) in items" :key="i"><p class="pl-4">{{ item }}</p></li>
    </ul>
</template>
<script>
export default {
    name  : 'CustomUnorderedList',
    props : {
        items : {
            type    : Array,
            default : null
        }
    }
};
</script>
<style scoped lang="scss">
.custom-ul-list {
    list-style: none;

    li {
        position: relative;

        &:before {
            content: "";
            //background-image: url(../../assets/img/bullets/bullet.png);
            background-size: contain;
            background-repeat: no-repeat;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 2px solid var(--text-2);
            top: 4px;
            left: 0;
            position: absolute;
        }
    }
} </style>
