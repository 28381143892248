<template>
    <div class="border-r-2 font-inter-medium fs-lg-2">
        <table class="table table-bordered border-r-2">
            <thead>
            <tr class="bg-4 main-header">
                <th rowspan="1" colspan="2" class="pos-r w-100">
                    <div class="font-inter-medium text-center fs-lg-2">
                        Auction Id {{ data.auction_details.id }}
                    </div>
                    <div class="text-center">
                        <btn @click="$emit('cancel')" text="Cancel Auction" color="secondary"
                             v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_cancel_live_auction)
                                    && data.data.status !== 'Ended'"
                             class="pos-a-r-0-resp h-100"></btn>
                    </div>
                </th>
            </tr>
            </thead>
            <!--            <tbody class="section-items">-->
            <tr class="w-100">
                <td class="font-inter-medium">
                    <div>
                        <span>Auction Title : </span>
                        <span>{{ data.auction_details.title }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Auction Status : </span>
                        <span>{{ data.data.status }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Description : </span>
                        <span>{{ data.auction_details.description }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Start Date : </span>
                        <span>{{ data.important_dates.auction_start_date }} | {{
                                data.important_dates.auction_start_time
                            }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Scheduled End Time : </span>
                        <span>{{ data.important_dates.auction_end_date }} | {{
                                data.important_dates.auction_end_time
                            }} IST</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Reserve Price : </span>
                        <span>₹ {{ data.auction_details.reserve_price }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Tender Fee : </span>
                        <span>₹ {{ data.auction_details.tender_fee }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>BID Price : </span>
                        <span v-if="data.auction_details.price_bid!=='Applicable'">₹</span>
                        <span>{{ data.auction_details.price_bid }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Increment Value : </span>
                        <span>₹ {{ data.auction_details.increment_value }}</span>
                    </div>
                </td>
            </tr>
            <!--            </tbody>-->
            <thead>
            <tr class="bg-1">
                <th rowspan="1" colspan="2" class="pos-r w-100">
                    <div class="font-inter-medium text-center w-100 fs-lg-2">
                        Event Details
                    </div>
                </th>
            </tr>
            </thead>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Event Type : </span>
                        <span>{{ data.event_details.event_type }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Ref No : </span>
                        <span>{{ data.event_details.ref_no }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Event Number : </span>
                        <span>{{ data.event_details.event_number }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Event Title : </span>
                        <span>{{ data.event_details.event_title }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Event Bank : </span>
                        <span>{{ data.event_details.event_bank_name }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Event Bank Branch : </span>
                        <span>{{ data.event_details.branch }}</span>
                    </div>
                </td>
            </tr>
            <thead>
            <tr class="bg-1">
                <th rowspan="1" colspan="2" class="pos-r w-100">
                    <div class="font-inter-medium text-center w-100 fs-lg-2">
                        Property Details
                    </div>
                </th>
            </tr>
            </thead>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Category : </span>
                        <span>{{ data.property_details.property_category }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span>Property Sub-Category : </span>
                        <span>{{ data.property_details.property_subcategory }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-inter-medium">
                    <div>
                        <span>Borrower Name : </span>
                        <span>{{ data.property_details.borrower_name }}</span>
                    </div>
                </td>
                <td class="font-inter-medium">
                    <div>
                        <span> </span>
                        <span></span>
                    </div>
                </td>
            </tr>
            <thead>
            <tr class="bg-1">
                <th rowspan="1" colspan="2" class="pos-r w-100">
                    <div class="font-inter-medium text-center w-100 fs-lg-2">
                        Documentation Details
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td v-if="data.document_details.nit_documents" @click="viewFile(data.document_details.nit_documents)"
                    class="font-inter-medium">
                    <div class="fl-te-c cursor-pointer">
                        <span class="cursor-pointer">Auction NIT Documents</span>
                        <span class="fa fa-eye"/>
                    </div>
                </td>
                <td @click="viewFile(data.document_details.annexure)" class="font-inter-medium">
                    <div class="fl-te-c cursor-pointer">
                        <span class="cursor-pointer">Annexure</span>
                        <span class="fa fa-eye"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td @click="viewFile(data.document_details.bid_form)" class="font-inter-medium">
                    <div class="fl-te-c cursor-pointer">
                        <span class="cursor-pointer">Bid Form</span>
                        <span class="fa fa-eye"/>
                    </div>
                </td>
                <td @click="viewFile(data.document_details.bidder_declaration_annexure)" class="font-inter-medium">
                    <div class="fl-te-c cursor-pointer">
                        <span class="cursor-pointer">Bid Declaration Annexure</span>
                        <span class="fa fa-eye"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" rowspan="1" class="btn-bg-gradient-secondary text-white">
                    <div class="w-100 h-100  border-r--2 border-none fl-x-cc">
                        <span class="mr-2"><i class="fa fa-bullhorn fa-2x"></i></span>
                        <span class="mr-3">Auction will end in</span>
                        <span class="fs-lg-5 font-inter-bold">
                                    <Countdown :end="data.important_dates.auction_end_date_counter_format"></Countdown>
                            </span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Countdown from 'vuejs-countdown';
import { mapGetters } from 'vuex';

export default {
    name : 'AuctionDetailsTable',

    components : { Countdown },

    computed : {
        ...mapGetters(['currentUser'])
    },

    props : {
        data : {
            type     : Array,
            required : true
        }
    },

    methods : {
        viewFile (item) {
            window.open(item);
        }
    }
};
</script>

<style scoped lang="scss">
.section-items {
    tr {
        display: flex;
        width: 100% !important;
    }

    td {
        padding: var(--spacer-2) var(--spacer-4);
        width: 100%;
    }
}

tr {
    display: flex;
    width: 100% !important;

    &.main-header {
        display: flex !important;
        width: unset !important;
    }
}

td {
    padding: var(--spacer-3) var(--spacer-4) !important;
    width: 100%;
}

</style>
