    <template>
    <inner-page-card :linkBack="true" heading="Live Auction Details" class="mt-3" :header-bullet="true">

        <template #note>
            <span class="text-secondary-1 font-inter-medium">Note</span> :
            <span class="fs--1">This page will automatically refresh in every 60 seconds</span>
        </template>

        <div>
            <div v-if="loading" class="fl-eq">
                <loading-animation/>
            </div>

            <auction-details-table v-else @cancel="cancelReason" :data="auctionData"></auction-details-table>

            <tabs align="center" class="c-tabs-gray">
                <tab title="Access Logs">
                    <div v-if="logLoading" class="text-center text-muted pt-3">
                        <loading-animation></loading-animation>
                        <p>Please wait while data is being loaded...</p>
                    </div>

                    <div v-else-if="logError" class="text-danger fl-te-c p-3">
                        <div>
                            <error-icon/>
                            Failed to load data from the server.
                        </div>

                        <div>
                            <a class="pointer" @click="loadAccessLog()"><i class="fa fa-refresh"></i> reload</a>
                        </div>

                    </div>

                    <div v-else>
                        <custom-unordered-list v-if="accessLogs.length > 0" :items="accessLogs"/>
                        <div class="text-center" v-else>No Data</div>
                    </div>
                </tab>

                <tab title="Summary">
                    <div v-if="loading1" class="text-center text-muted pt-3">
                        <loading-animation></loading-animation>
                        <p>Please wait while data is being loaded...</p>
                    </div>
                    <ul v-if="summary!=='' && !loading1" class="custom-ul-list solution-cont font-inter-medium">
                        <li class="pl-4">{{ summary.message_1 }}</li>
                        <li class="pl-4">{{ summary.message_2 }}</li>
                    </ul>
                    <p v-if="!summary && !loading1">No summary available</p>
                </tab>

                <tab title="Reports">
                    <div class="fl-eqh-c">
                        <btn text="Download" icon="fa fa-download" class="mt-3" type="button"
                             @click="downloadReport"/>
                    </div>
                </tab>
            </tabs>
        </div>

        <modal title="Cancel Auction" ref="cancelModal" width="50r" :no-close-on-backdrop="true"
               header-color="primary">
            <cancel-auction @success="cancelAuction"/>
        </modal>

    </inner-page-card>
</template>

<script>
import AuctionDetailsTable from '../../components/auction/live-auction/AuctionDetailsTable';
import CustomUnorderedList from '../../components/ui/CustomUnorderedList';
import CancelAuction from '@/views/auction/cancel-auction/CancelAuction';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name : 'LiveAuctionDetailsPage',

    components : { CustomUnorderedList, AuctionDetailsTable, CancelAuction },

    data () {
        return {
            auctionData : {},
            accessLogs  : [],
            summary     : '',
            reports     : [],

            loading  : true,
            loading1 : false,

            logLoading : true,
            logError   : false,

            loadData          : null,
            loadAccessLogData : null
        };
    },

    mounted () {
        this.loadDetails();
        this.loadSummary();
        this.loadAccessLog();

        console.log(this.$router);
        console.log(this.$route);

        this.loadDataOnAInterval();
        this.loadAccessLogOnAInterval();
    },

    beforeDestroy () {
        clearInterval(this.loadData);
        clearInterval(this.loadAccessLogData);
    },

    methods : {
        loadDataOnAInterval () {
            this.loadData = setInterval(() => {
                this.loadDetails();
            }, 10000);
        },

        loadAccessLogOnAInterval () {
            this.loadAccessLogData = setInterval(() => {
                this.loadAccessLog();
            }, 15000);
        },

        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.auction.auction.live.details,
                {
                    id : this.$route.params.id
                }
            );
            const json = response.data.data;
            if (response.data.success) {
                this.auctionData = { ...json.auction_data };
                this.loading = false;
            } else {
                this.loading = false;
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
        },

        cancelReason () {
            this.$refs.cancelModal.show();
        },

        async loadSummary () {
            this.loading1 = true;
            const response = await axios.form(urls.auction.auction.live.summaryView,
                { auction_id : this.$route.params.id });
            const json = response.data;
            if (json.success === true) {
                this.summary = json;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
            this.loading1 = false;
        },

        async downloadReport () {
            this.downloading = true;
            const response = await axios.form(urls.auction.auction.live.reportDownload,
                { auction_id : this.$route.params.id });
            const json = response.data;
            if (json.error === false) {
                window.open(json.url);
                this.$notify('Successfully downloaded the report.', 'Success', {
                    type : 'success'
                });
            } else {
                this.$notify('Could not download the auction, Please try again later', 'Failed', {
                    type : 'danger'
                });
            }
            this.downloading = false;
        },

        async cancelAuction (model) {
            const that = this;
            this.$refs.cancelModal.close();
            this.loading = true;
            const sendModel = {
                ...model,
                id : this.$route.params.id
            };
            this.loading = true;
            const response = await axios.form(urls.auction.auction.live.cancel, { ...sendModel }).catch(function (exception) {
                console.log('exception : ', exception);
                that.$notify('Could not cancel the auction, Please try again later', 'Failed', {
                    type : 'danger'
                });
                that.loading = false;
            });
            const json = response.data;
            if (json.error === false) {
                this.$notify('Successfully cancelled the auction.', 'Success', {
                    type : 'success'
                });
                await this.$router.push({ path : '/live-auction/' });
            } else {
                this.$notify('Could not cancel the auction, Please try again later', 'Failed', {
                    type : 'danger'
                });
            }
            this.loading = false;
        },

        loadAccessLog () {
            const that = this;
            that.logLoading = true;
            that.logError = false;

            axios.form(urls.auction.auction.live.accessLog, { auction_id : that.$route.params.id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.accessLogs = [...json.log_data];
                    that.logLoading = false;
                } else {
                    that.accessLogs = [];
                    that.logLoading = false;
                }
            }).catch(function (exception) {
                console.log('exception at access log : ', exception);
                that.logLoading = false;
                that.logError = true;
            });
        }
    }
};
</script>
<style scoped lang="scss">
.custom-ul-list {
    list-style: none;

    li {
        position: relative;

        &:before {
            content: "";
            //background-image: url(../../assets/img/bullets/bullet.png);
            background-size: contain;
            background-repeat: no-repeat;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 2px solid var(--text-2);
            top: 4px;
            left: 0;
            position: absolute;
        }
    }
} </style>
